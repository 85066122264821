.main-sidebar,
.navbar,
.navbar-static-top {
  background-color: #fbfdf8 !important;
}

.sidebar > li.active {
  background-color: #f2eee8 !important;
}

.content-wrapper,
.content,
.col-xs-12,
.row {
  background-color: #ffffff !important;
}

.react-flow__node {
  background-color: #83afaa !important;
}

.questionCol {
  padding: 20px;
  border-right: 1px dotted #cda58e;
  height: 90%;
}

.attributeCol {
  padding: 20px;
}

.bgContainer {
  background-color: #ede4de !important;
  margin-top: 5px;
}

.questionItem {
  background-color: #ede4de;
  border: 1px solid #cda58e;
  margin-top: 10px;
  padding: 10px;
  cursor: pointer;
}

.questionItemActive {
  background-color: #cda58e;
  border: 1px solid #cda58e;
  margin-top: 10px;
  padding: 10px;
  cursor: pointer;
}

.optionItem {
  background-color: #ede4de;
  border: 1px solid #cda58e;
  margin-top: 10px;
  padding: 10px;
  cursor: pointer;
}

.optionItemActive {
  background-color: #cda58e;
  border: 1px solid #cda58e;
  margin-top: 10px;
  padding: 10px;
  cursor: pointer;
}

.questionAttrContainer {
  background-color: #ede4de;
  border: 1px solid #cda58e;
  margin-bottom: 10px;
  padding: 10px;
  height: auto !important;
  margin-top: 10px;
  overflow-y: hidden;
}

.channelSelection {
  border-radius: 4px;
  background-color: #f2eee8;
  height: 30px;
  padding-top: 5px;
  padding-left: 10px;
  margin-bottom: 20px;
  font-size: 12pt;
  cursor: pointer;
}

.logo {
  background-size: 90%;
  background-repeat: no-repeat;
  margin-top: 20px;
  background-position: center;
}

.logoCoW {
  background-image: url(./assets/logo.png) !important;
}
.logoLvK {
  background-image: url(./assets/LVKlogo.png) !important;
}

.sidebar {
  margin-top: 30px;
}

.imagePreview {
  width: 100px;
  height: 100px;
  max-width: 100%;
}

.breadcrumb {
  display: none;
}

.saveBtn {
  margin-right: 15px !important;
}

.sidebar-toggle {
  border-right: 0px solid #ffffff !important;
  padding-top: 30px !important;
}

table > tbody > tr > td {
  cursor: pointer;
}

.kpiContainer {
  background-color: #ede4de !important;
  color: #311315 !important;
  margin-top: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
}

.kpiContainer > span {
  color: #311315 !important;
  font-size: 28pt;
}

.btn-cuw {
  background-color: #311315;
  color: #ffffff;
}

ul.sidebar-menu > li:last-child {
  margin-top: 50px;
}

.errorLine {
  background-color: #eabcb7 !important;
  border: 1px solid #f0a8ac;
  margin-top: 10px;
  padding: 10px;
  cursor: pointer;
  color: #000;
}

.scrollList {
  height: 130px !important;
  overflow-y: scroll;
  border: 1px solid #f0a8ac;
  margin: 20px;
  max-width: 90%;
  padding-left: 0px !important;
}

.appInformation {
  margin-left: 20px;
  margin-top: 150px;
  color: #cccccc;
  font-size: 9pt;
  position: absolute;
  bottom: 20px;
}

.attributeCounter {
  font-size: 10pt;
  border: 1px solid #311315;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  text-align: center;
}

.attributeCounterWarning {
  font-size: 10pt;
  border: 1px solid #311315;
  color: #ffffff;
  background-color: #311315;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  text-align: center;
}

.filter {
  background-image: url(./assets/filter.png);
  background-size: 90%;
  background-repeat: no-repeat;
  background-position: center;
  height: 20px !important;
  width: 20px !important;
  position: absolute;
  left: 85%;
  margin-top: -20px;
}

.CalendarDay__selected_span {
  background-color: #cccccc !important;
  border: 1px solid #e4e7e7 !important;
}

.react-date-picker__wrapper,
.react-date-picker {
  padding: 5px !important;
  width: 100% !important;
  max-width: 100% !important;
}

.react-calendar__tile--now {
  background-color: #cccccc !important;
}

.react-calendar__tile--active {
  background-color: #ede4de !important;
  color: #000000 !important;
}

.nav-tabs-custom > .nav-tabs > li.active {
  border-top-color: #ede4de !important;
}

.researchItemLine {
  padding: 10px !important;
  padding-left: 20 !important;
  cursor: pointer;
  margin-right: 40px;
}

.researchItemLine:nth-child(2n) {
  background-color: #f4f4f5 !important;
}

.researchItemLine > h4 {
  padding-left: 20 !important;
}

.custom-overlay {
  z-index: 999 !important; /* Stelle sicher, dass der Wert hoch genug ist */
  background-color: rgba(0, 0, 0, 0.3) !important;
  transition: background-color 0.3s ease-in-out !important;
  width: 100% !important;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 230px;
}

.custom-content {
  z-index: 1001; /* Höher als das Overlay */
  position: absolute;
  top: 50%;
  left: 45% !important;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  outline: none;
}
